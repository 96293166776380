/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { set } from 'lodash';
import moment from 'moment-mini';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';
import RedemptionsManager from '../../managers/Redemptions';

function PointsHistory({
  loyaltyAccountId,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('Loading points history! One second...');
  const [pointsHistoryList, setPointsHistoryList] = useState([]);

  const getPointsHistory = async () => {
    try {
      const pointsHistory = await AccountManager.getPointsHistory(AccountManager.getToken(), loyaltyAccountId);
      console.log(pointsHistory);
      if (pointsHistory && pointsHistory.items) {
        setIsLoading(false);
        if (pointsHistory.items && pointsHistory.items.length > 0) {
          setPointsHistoryList(pointsHistory.items);
        }
      }
    } catch (error) {
      toast.error('There was an error loading your points history. Please try again later.');
    }
  };

  useEffect(() => {
    getPointsHistory();
  }, []);

  return (
    <div className="loyalty-modal">
      {isLoading && <Throbber throbberText={loadingText} />}
      <div className="loyalty-modal__header">
        <div className="loyalty-modal__title">Points History</div>
        <div className="loyalty-modal__description">All of your activities that consumes or gains points will be reflected here.</div>
      </div>
      <div>
        <div className="loyalty-modal__offers-container">
          <table className="loyalty-modal__table">
            <tr>
              <th>Date</th>
              <th>Activity</th>
              <th>Amount</th>
            </tr>

            {pointsHistoryList.map((activity, i) => (
              <tr key={i}>
                <td>{moment(activity.date).format('MMMM DD, YYYY')}</td>
                <td>
                  {activity.reason ? activity.reason : RedemptionsManager.getPointsType(activity.originType)}
                </td>
                <td>
                  <div className={activity.action === 1 ? 'loyalty-modal__table--points -positive' : 'loyalty-modal__table--points -negative'}>
                    {activity.action === 1 ? '+' : '-'}
                    {activity.amount}
                  </div>
                  <span>Redeemable points</span>
                </td>
              </tr>
            ))}
                    
          </table>
        </div>
      </div>
    </div>
  );
}

export default PointsHistory;
